<template>
    <div class='grid crud-demo'>
        <div class='col-12'>
            <div class='card'>
                <Toast />
                <Toolbar class='mb-4'>
                    <template v-slot:end>
                        <div class='my-2'>
                            <Button label='Adicionar' icon='pi pi-plus' class='p-button-success mr-2'
                                    @click='openNew' />
                            <Button label='Deletar' icon='pi pi-trash' class='p-button-danger'
                                    @click='confirmDeleteSelected'
                                    :disabled='!selectedCategories || !selectedCategories.length' />
                        </div>
                    </template>
                </Toolbar>

                <DataTable ref='dt' :value='categories' v-model:selection='selectedCategories' dataKey='id'
                           :paginator='true' :rows='10' :filters='filters'
                           paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                           :rowsPerPageOptions='[5,10,25]'
                           currentPageReportTemplate='Mostrando {first} ~ {last} total {totalRecords} categorias'
                           responsiveLayout='scroll'>
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='m-0'>Categorias</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Pesquisar...' />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode='multiple' headerStyle='width: 3rem'></Column>
                    <Column headerStyle='width: 20rem' field='name' header='Nome' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 10rem'></Column>
                    <Column headerStyle='width: 10rem'></Column>
                    <Column headerStyle='width: 20rem'></Column>
                    <Column headerStyle='width: 8rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-success mr-2'
                                    @click='editCategory(slotProps.data)' />
                            <Button icon='pi pi-trash' class='p-button-rounded p-button-warning'
                                    @click='confirmDeleteCategory(slotProps.data)' />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible='categoryDialog' :style="{width: '450px'}" header='Criar / Editar Categoria'
                        :modal='true' class='p-fluid'>
                    <div class='field'>
                        <label for='name'>Nome*</label>
                        <InputText id='name' v-model.trim='category.name' required='true' autofocus
                                   :class="{'p-invalid': v$.category.name.$invalid && submitted}" />
                        <small
                            v-if='(v$.category.name.$invalid && submitted) || v$.category.name.$pending.$response'
                            class='p-error'>{{ v$.category.name.required.$message.replace('Value', 'Nome') }}</small>
                    </div>
                    <h5>Mostrar em</h5>
                    <div class='grid'>
                        <div class='col-12 md:col-4'>
                            <div class='field-checkbox'>
                                <Checkbox id='expense' :binary='true' v-model='category.expense' />
                                <label for='expense'>Despesa</label>
                            </div>
                        </div>
                        <div class='col-12 md:col-4'>
                            <div class='field-checkbox'>
                                <Checkbox id='income' :binary='true' v-model='category.income' />
                                <label for='income'>Receita</label>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <Button label='Cancelar' icon='pi pi-times' class='p-button-text' @click='hideDialog' />
                        <Button label='Salvar' icon='pi pi-check' class='p-button-text'
                                @click='saveCategory(!v$.$invalid)' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteCategoryDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='category'>Tem certeza que deseja excluir a categoria <b>{{ category.name
                            }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteCategoryDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text' @click='deleteCategory' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteCategoriesDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='category'>Tem certeza que deseja excluir as categorias selecionadas ?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteCategoriesDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text'
                                @click='deleteSelectedCategories' />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import CategoryService from '@/services/category.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            categories: null,
            categoryDialog: false,
            deleteCategoryDialog: false,
            deleteCategoriesDialog: false,
            category: {},
            selectedCategories: null,
            filters: {},
            submitted: false,
        };
    },
    validations: {
        category: {
            name: {
                required,
            },
        },
    },
    categoryService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.fetchCategories();
    },
    methods: {
        formatCurrency(value) {
            //TODO use database to set this.
            if (value) return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        openNew() {
            this.category = {};
            this.submitted = false;
            this.categoryDialog = true;
        },
        hideDialog() {
            this.categoryDialog = false;
            this.submitted = false;
        },
        async saveCategory(isFormValid) {
            if (!isFormValid) {
                this.submitted = true;
                return;
            }

            if (this.category.name.trim()) {
                if (this.category.id) {
                    try {
                        let resp = await CategoryService.update(this.category);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                } else {
                    try {
                        let resp = await CategoryService.create(this.category);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                }
            }
            await this.fetchCategories();
            this.categoryDialog = false;
            this.category = {};
        },
        editCategory(category) {
            this.category = { ...category };
            console.log(this.category);
            this.categoryDialog = true;
        },
        confirmDeleteCategory(category) {
            this.category = category;
            this.deleteCategoryDialog = true;
        },
        async deleteCategory() {
            try {
                let resp = await CategoryService.delete(this.category.id);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchCategories();
            this.deleteCategoryDialog = false;
            this.category = {};
        },
        confirmDeleteSelected() {
            this.deleteCategoriesDialog = true;
        },
        async deleteSelectedCategories() {
            try {
                CategoryService.deleteMany(this.selectedCategories).then(resp => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: resp.data.message,
                        life: 3000,
                    });
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchCategories();
            this.deleteCategoriesDialog = false;
            this.selectedCategories = null;
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async fetchCategories() {
            let resp = await CategoryService.find();
            this.categories = resp.data;
        },
    },
};
</script>

<style scoped lang='scss'>
@import '../../assets/demo/styles/badges.scss';
</style>
